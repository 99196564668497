@import 'assets/styles/mixins.scss';

/* Using SCSS variables to store breakpoints */
$breakpoint-tablet: 6800px;
@media (min-width: $breakpoint-tablet) {
  .AppSearch {
    width: 100vw;
  }
}

.SearchInput {
  float: left;
  padding: 0px;
  margin: 4px;
  max-width: 700px;
  min-width: 600px;
  line-height: 0px;
  background-color: white;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;

  span {
    margin: 0px;
    padding: 0px;
    background-color: transparent;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
  }
}

.AppLayout {
  position: relative;
  // height: 100%;
  padding: 0px;
  margin: 0px;
  // overflow: hidden;
  overflow: auto;
  // text-align: left;
  background: transparent;
  border: 0px;
}
